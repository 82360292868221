import { Alert, Box, Link, Stack } from "@mui/material";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { NucleusButton } from "../components/NucleusButton";
import { ProfileMenu } from "../components/ProfileMenu";
import AuthContext from "../context/AuthContext";
import { NewtCloudLogo } from "../img/NewtCloudLogo";

export default function AppLayout() {
  const {
    actions: { isLoggedIn },
  } = useContext(AuthContext);

  return (
    <div className="customBody customFamily">
      <nav
        className="top-0 border-b transition duration-200 z-50 ease-in-out animate-header-slide-down-fade border-transparent backdrop-blur-sm px-2 py-4 lg:p-4"
        data-headlessui-state=""
        id="__FPSC_ID_1_1710833276946"
      >
        <Alert icon={false} sx={{ marginBottom: 2 }} severity="info">
          Welcome to Nucleus! This website is currently in pre-launch mode.
          Stay tuned for exciting updates and the full launch soon!
        </Alert>
        <div className="mx-auto max-w-7xl">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%" }}
          >
            <a target="_parent" href="/">
              <Box sx={{ height: '75px' }} className="logo">
                <NewtCloudLogo />
              </Box>
            </a>


            {!isLoggedIn() && (
              <Link href="/signin">
                <NucleusButton>Sign in</NucleusButton>
                {/* <Button>
                  <u>Sign in</u>
                </Button> */}
              </Link>
            )}

            {isLoggedIn() && (<ProfileMenu />)}
          </Stack>
        </div>
      </nav>
      <main>
        <div className="relative overflow-hidden isolate">
          <div className="relative">
            <Outlet />
          </div>
        </div>
      </main>
      <footer>
        <div>
          {/* <h4>Legal</h4> */}
          <nav>
            <ul>
              <li><Link href="/faqs" color="inherit" underline="none">FAQs</Link></li>
              <li><Link href="/terms" color="inherit" underline="none">Terms of Use</Link></li>
              <li><Link href="/privacy" color="inherit" underline="none">Privacy Policy</Link></li>
            </ul>
          </nav>
        </div>
        <div>
          <p>&copy; 2024 Nucleus Technologies Inc</p>
        </div>
      </footer>
    </div>
  );
}
