import { Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../interfaces/IHomeFormInput";
import { useContext } from "react";
import ApiContext from "../context/ApiContext";

interface TeamMemberHeaderProps {
  index: number;
}

export function TeamMemberHeader(props: TeamMemberHeaderProps) {
  const { index } = props;
  const {
    data: {
      agents
    }
  } = useContext(ApiContext);

  const {
    control,
  } = useFormContext<IHomeFormInput>();
  
  return (
    <Controller
      control={control}
      name={`agents.${index}.id`}
      render={({ field }) => {
        const id = field.value
        const agent = agents.find((agent)=>agent.id == id)
        let name = "(New Member)";
        if (agent?.first_name && agent?.last_name)
          name = agent?.first_name + " " + agent?.last_name;
        return(
          <Typography variant="h6">
            {name}
          </Typography>
        )
      }}/>
    )
}
