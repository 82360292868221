import { Box, Button, Stack } from "@mui/material";
import { useContext, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import ApiContext from "../../context/ApiContext";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { ModelCardLayout } from "../ModelCardLayout";
import { ResetCardDialog } from "../dialog/ResetCardDialog";

export interface ModelCardProps {}

export function ModelCardSection() {
  const {
    data: { defaultCards },
  } = useContext(ApiContext);

  const { control, reset, getValues } = useFormContext<IHomeFormInput>();
  useFieldArray({
    control,
    name: "cards",
  });

  const handleReset = () => {
    reset({
      ...getValues(),
      cards: defaultCards,
    });
    setDialogOpen(false)
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
    <ResetCardDialog 
        open={dialogOpen} 
        onClose={() => setDialogOpen(false)}      
        onReset={handleReset}
    />
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      spacing={2}
    >
      <ModelCardLayout />
      <Box sx={{ paddingLeft: 2 }}>
        <Button variant="outlined" type="button" 
        onClick={() => setDialogOpen(true)}
>
          Reset
        </Button>
      </Box>
    </Stack>
    </>
  );
}
