import * as React from "react";
import ApiContext from "../context/ApiContext";
import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";


declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
const properties = {
  display: 'grid',
  placeItems: 'center',
  height: '100vh',
};
function PricingPage() {
  const {
    actions: { createStorePriceTableSession }
  } = useContext(ApiContext);

  const apiKey = process.env.REACT_APP_STRIPE_API_KEY;

  const {
    data: { account_id },
  } = useContext(ApiContext);

  const [loading, setLoading] = useState(true);
  const [customerSecret, setCustomerSecret] = useState("");
  const [price_id, setPriceId] = useState("");

  const loadStoreUrl = async () => {
    const {price_id: p, secret} = await createStorePriceTableSession( );
    setPriceId(p)
    setCustomerSecret(secret)
    setLoading(false)
  };

  useEffect(() => {
    loadStoreUrl();
  }, []);

  if (loading) {
    return (<Box sx={properties}>
      <CircularProgress size={50} />
    </Box>)
  }
  return (
    <stripe-pricing-table
      pricing-table-id={price_id}
      publishable-key={apiKey}
      client-reference-id={account_id}
      customer-session-client-secret={customerSecret ? customerSecret : undefined}
    ></stripe-pricing-table>
  );
}

export default PricingPage;
