import { Logout } from "@mui/icons-material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApiContext from "../context/ApiContext";
import AuthContext from "../context/AuthContext";
import { AccessLevel } from "../interfaces/IGetUserResult";
import { ProfileDialog } from "./dialog/ProfileDialog";
import { SubmitTicketDialog } from "./dialog/SubmitTicketDialog";

export interface ProfileMenuProps {
  disabled?: boolean;
}

export function ProfileMenu(props: ProfileMenuProps) {
  const { disabled } = props
  const navigate = useNavigate();
  const {
    data: {
      email,
      accessLevel
    },
  } = useContext(ApiContext);
  const {
    actions: { logout },
  } = useContext(AuthContext);


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setProfileDialogOpen(true)
  }

  const handleUpgrade = async (e: any) => {
    e.preventDefault();
    navigate(accessLevel === AccessLevel.FREE ? "/upgrade" : "/store");
  };

  const handleFaq = async (e: any) => {
    e.preventDefault();
    navigate("/faqs");
  };


  const handleSubmitTicket = () => {
    setSubmitTicketDialogOpen(true)
  }

  const handleSignout = async (e: any) => {
    e.preventDefault();
    handleClose()
    logout();
    navigate("/signin");
  };
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [submitTicketDialogOpen, setSubmitTicketDialogOpen] = useState(false);
  return (
    <>
      <ProfileDialog open={profileDialogOpen} onClose={() => setProfileDialogOpen(false)} />
      <SubmitTicketDialog open={submitTicketDialogOpen} onClose={() => setSubmitTicketDialogOpen(false)} />
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="medium"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={disabled}
        >
          <AccountCircleIcon sx={{ fontSize: 40 }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfile}>
          <Avatar />{email || "Guest"}
        </MenuItem>
        <Divider sx={{padding:0}}/>
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        {accessLevel !== AccessLevel.PRO && (<MenuItem onClick={handleUpgrade}>
          <ListItemIcon>
            <ShoppingCartIcon fontSize="small" />
          </ListItemIcon>
          Upgrade to Premium
        </MenuItem>)}
        <MenuItem onClick={handleFaq}>
          <ListItemIcon>
            <QuestionAnswerIcon fontSize="small" />
          </ListItemIcon>
          FAQ
        </MenuItem>
        {accessLevel === AccessLevel.PRO && (<MenuItem onClick={handleSubmitTicket}>
          <ListItemIcon>
            <ContactSupportIcon fontSize="small" />
          </ListItemIcon>
          Support
        </MenuItem>)}
        <Divider sx={{padding:0}}/>
        <MenuItem onClick={handleSignout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
}
