import {
    Box,
    Grid,
    Stack
  } from "@mui/material";
  
  export default function PrivacyView() {
    return (
      <Box sx={{ margin: "auto" }}>
        <Grid container spacing={2} margin={0} direction="column">        
        <Stack component="section" direction="column" className="white legal" padding={2}>
            <Box className="text" >
            

            <h1 >Privacy Policy</h1>


<h2>1. <u>Introduction</u></h2>

<p>Nucleus (“Nucleus”) and its subsidiaries and affiliates (collectively “Companies”) operate a website at <u><a href='http://www.nucleus.com'>www.nucleus.com</a></u> (the “website”) to enable you to learn more about Nucleus and its products and services (collectively “services”).  We are committed to safeguarding the privacy of our customers and website visitors. This Privacy Policy (“<b>Privacy Policy</b>”) covers Nucleus’ handling, use, and disclosure of information collected from you through the use of our website and in the ordinary course of business. It does not, however, impose any limits on Nucleus’ collection, use or disclosure of the following (i) information that is publicly available; or (ii) the name, title or business address or telephone number of an employee of an organization.</p>

<h2>2. <u>Acceptance</u></h2>
<p>You should review this Privacy Policy carefully, and be sure you understand it, prior to using the website or otherwise providing any information to Nucleus in connection with the purchase and use of our products.  If you use the website or provide information to us, it will be deemed to be acceptance by you of this Privacy Policy.  If you do not agree to this Privacy Policy, you should not use or otherwise provide any information to Nucleus and should immediately terminate your use of the website.  For purposes of this section, accessing the website only to review this Privacy Policy is not deemed to be use of the website.</p>

<h2>3. <u>Information</u></h2>
<p>In this Privacy Policy, the following information is covered:</p>

<ul>
<li>“<i><b>Analytical Information</b></i>” means all Non-Personal Information obtained through the use of cookies, web beacons, Ad-IDs or other tracking technologies, local shared objects (used to store your preferences or display content) and server log files (used to track, among other things, (i) your search terms, (ii) your computer’s access date and time, browser, connection speed, Internet service provider, language, location, manufacturer, visit details, and operating system, and (iii) whether or not you opened e-mail messages and other electronic communications from Nucleus, and if you did, the times they were opened);</li>
<li>“<i><b>Collected Information</b></i>” means all (i) Personal Information and (ii) Non-Personal Information collected (gathered, acquired, recorded or otherwise obtained) by Nucleus;</li>
<li>“<i><b>Disclosure</b></i>” means the act of making information, including Personal Information, available to a third party;</li>
<li>“<i><b>Non-Personal Information</b></i>” means all information collected by Nucleus, whether electronically or manually, through (i) Nucleus’ website, (ii) e-mail messages and other electronic communications that you may send to Nucleus, and (iii) other sources in the ordinary course of Nucleus’ business, that is not Personal Information (including, but not limited to, any Analytical Information); and</li>
<li>“<i><b>Personal Information</b></i>” means all information collected by Nucleus, whether electronically or manually, through (i) the website, (ii) e-mail messages and other electronic communications that you may send to Nucleus, and (iii) other sources in the ordinary course of Nucleus’ business, that relates to an individual and that identifies, or can be used in conjunction with other readily-accessible information to identify, such individual (including, but not limited to, name, e-mail address, physical address, phone number, device ID or other persistent identifiers tied to your computer or device).  For a customer, such information includes, but is not limited to, a customer’s credit information, billing records, service and equipment information, and any recorded complaints.</li>
</ul>

<h2>4. <u>Electronic Communications</u></h2>

<p>Whether or not you have previously sent Nucleus an e-mail message, you consent to us sending you e-mail messages and other electronic communications (a) in connection with your use of the website, (b) in connection with the purchase of services from Nucleus; (c) in the ordinary course of business, or (c) for any other legitimate business purpose (including, but not limited to, marketing).</p>

<p>Since Nucleus endeavors to send e-mail messages and other electronic communications only to individuals desiring to receive them, you can unsubscribe to such e-mail messages or other electronic communications at any time by contacting Nucleus as set forth in Section 18 or by following the directions contained in such e-mail messages or other electronic communications.  Any request to unsubscribe to e-mail or other electronic communications will likely be effective within 48 hours after your request is received by Nucleus.</p>

<h2>5. <u>Collection of Information</u></h2>

<p>Except for any Collected Information obtained automatically through the website, no Collected Information is obtained from you, unless it is voluntarily provided.  For example, Nucleus may collect the full name, email address, postal address, phone number and other identification data from its customers and other individuals who sign-up to receive information about Nucleus and its services.  Even when you voluntarily provide such information, you may later withdraw your consent to further use of your Personal Information by contacting us through a method set forth in Section 18.</p>

<p>Regardless of the method used to obtain Collected Information, Nucleus will only collect and retain Personal Information in a manner that is consistent with: (1) all applicable privacy and security laws, (2) the purposes for which the Personal Information is provided and (3) Nucleus’ other legitimate business purposes (including, but not limited to, marketing). You are responsible for obtaining any approvals, authorizations, consents, permissions and permits that are required in connection with your providing Nucleus with any information (including, but not limited to, any information relating to a third party).  You represent and warrant that all information was obtained by you in accordance with all applicable laws and that you have the right to provide Nucleus with such information for the purpose of purchasing services.</p>

<h2>6. <u>How We Use and Collect Analytical Information</u></h2>

<p>Analytical information is automatically collected through the website using cookies. These cookies help us save your preferences and track your interactions with the website. For example, we may collect information about your browser, network, device, the web pages you visited prior to coming to this website, as well as your clicks, pages visited, searches, and timestamps related to your use of the website. Your browser may allow you to block or delete cookies, including essential and non-essential ones. However, please note that if you block or delete cookies, certain features of the website may not function as intended.</p>
<p>Nucleus will use analytical information for the following purposes: (a) to record your use of the website or services, (b) to diagnose problems with the website or services, (c) to improve the website or services and enhance their usefulness for you and other users, (d) to monitor and analyze trends, usage, and activities in connection with our services, (e) for marketing, advertising, administrative, analytical, research, optimization, and other purposes, and (f) for other legitimate business purposes of Nucleus.</p>
<p>We may use third-party services, such as Google Analytics, to collect and process this analytical information. Some data analytics and online advertising providers may combine and use data collected through your use of our website with data they collect over time and across different websites for their own purposes. To learn more about how Google collects and processes data, please visit <u><a href='https://policies.google.com/technologies/partner-sites'>Google's Partner Sites Policy</a></u>. Additionally, you can explore the controls Google offers to manage the collection and use of your information at <u><a href='https://policies.google.com/privacy'>Google's Privacy Policy</a></u>. We recommend reviewing the privacy policies or notices provided at the links above.</p>

<h2>7. <u>How We Use Personal Information</u></h2>

<p>Nucleus does not sell Personal Information.  However, all Personal Information may be used by Nucleus for any legitimate business purpose, including marketing.  If Nucleus expressly states in this Privacy Policy or in another writing that any Personal Information will only be used for a specific purpose, Nucleus will only use such Personal Information for such purpose, unless you subsequently consent to its being used for another purpose.</p>
<p>Specifically, we may use your Personal Information to: (a) process, verify, and complete transactions; (b) send transactional messages, including providing updates pertaining to services, and responding to your comments, questions, and requests; (c) provide customer service and support; (d) send news, special offers and more; (e) investigate and prevent fraudulent transactions and other illegal activities; (f) verify compliance with the Privacy Policy; and (g) comply with and enforce applicable legal requirements, relevant industry standards, contractual obligations and our policies.</p>

<h2>8. <u>Disclosure of Collected Information</u></h2>

<p>Any Collected Information obtained by Nucleus, whether or not for a specific purpose, may be transferred to third parties designated by Nucleus (including, but not limited to sub-contractors or vendors engaged by Nucleus to perform administrative and technological functions) for any purposes for which Nucleus could use such Collected Information. If Collected Information is so transferred, Nucleus will have no responsibility for any action of the third party to whom or which such Collected Information is transferred.</p>
<p>Specifically, disclosure of Collected Information may be made to the following persons or entities:</p>
<ol type="a">
<li><i><b>Service Providers</b></i> We engage service providers to perform functions and provide services to us. We may share Collected Information, including Personal Information, with such service providers subject to obligations consistent with this Privacy Policy and any other appropriate confidentiality and security measures. For example, we may share your Personal Information with trusted partners who assist in operating our website and delivering our services (such as payment processing and delivery companies).  Nucleus will, to the extent practicable, limit disclosures to service providers to the minimum necessary to accomplish the intended purpose of such use disclosure or request. Nucleus will require these service providers to safeguard your Personal Information.</li>
<li><i><b>Authorized Personnel Nucleus</b></i> employees may have access to Collected Information as necessary in the normal course of our business.</li>
<li><i><b>Business Transfers</b></i> In the future, we may choose to buy or sell assets, or have engaged in discussions with a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or <i><b>other</b></i> sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding. In these types of transactions, customer information is typically one of the business assets that is transferred.</li>
<li><i><b>Government, Law Enforcement or Third Parties</b></i> Nucleus may disclose any Collected Information, including Personal Information, that we deem necessary, in our sole discretion and without your prior permission, to comply with any applicable law, regulation, legal process or governmental request. We also may exchange Collected Information, including Personal Information, with other companies and organizations to protect the rights, property, or safety of Nucleus and its personnel.  We reserve the right to disclose a user’s Personal Information if we believe, in good faith, that the user is in violation of the Privacy Policy, even without a subpoena, warrant or other court order.</li>
</ol>

<h2>9. <u>Payment Processing</u></h2>
<p>Nucleus uses Stripe to process payments made by customers for Products.  We do not collect or process payment information.  However, Nucleus ensures that obligations substantially no less protective than those set out in this Privacy Policy are imposed on our third party vendor by way of contract or other legally binding agreement, in particular providing sufficient guarantees that technical and organizational measures are implemented in such a manner to meet the requirements of this Privacy Policy and the relevant data protection laws.  We recommend that you review the privacy policy of Stripe to learn more about how it handles your personal information at <u><a href='https://www.stripe.com/privacy'>https://www.stripe.com/privacy</a></u>.</p>

<h2>10. <u>Third-Party Sites</u></h2>
<p>Nucleus’ website may contain links to, or be accessible from, websites provided by third parties (individually a “<b><u>Third-Party Site</u></b>”).  Your use of a Third-Party Site will be subject to its terms of use and other provisions, and you are responsible for complying with such terms and other provisions.  This Privacy Policy does not cover the privacy policies or practices of any Third-Party Site, and Nucleus is not responsible for any information you submit to, or is otherwise collected by, any Third-Party Site.  Nucleus is only responsible for Collected Information obtained by it (a) through your authorized use of the website, or (b) from other sources in the ordinary course of its business.  You should consult each Third-Party Site for its privacy policy or practice before submitting any information to, or otherwise using, such Third-Party Site.</p>

<h2>11. <u>Children</u></h2>

<p>The website is not intended for children under 13 years of age.  However, if a parent or guardian of a child who is under 13 years of age discovers that Personal Information of such child has been submitted to Nucleus through the website without the parent’s or guardian’s consent, Nucleus will use commercially reasonable efforts to remove such information from the website and servers at the parent’s or guardian’s request.  To request the removal of such Personal Information, the parent or guardian must contact Nucleus as set forth in Section 18, and provide all information requested by Nucleus to assist it in identifying the Personal Information to be removed.</p>

<h2>12. <u>Security of Collected Information</u></h2>

<p>Nucleus will protect Collected Information from loss, misuse and unauthorized access, alteration, destruction and disclosure as required by all applicable laws, including privacy and security laws.  Nucleus will, at a minimum, use commercially reasonable efforts to protect Personal Information from loss, misuse and unauthorized access, alteration, destruction and disclosure.  We implement reasonable administrative, technical, organizational and physical precautions to safeguard against the loss, misuse or alteration of your Collected Information, including Personal Information.  If you use the website, or otherwise provide information to us, you acknowledge that since no transmission of information over the Internet or electronic storage of information is completely secure, it is possible that Collected Information could be lost, misused or accessed, altered, destroyed or disclosed without authorization, even if Nucleus uses such reasonable efforts.</p>

<h2>13. <u>Users from Other Jurisdictions</u></h2>
<p>The website and business operations are controlled and operated by Nucleus from the Canada and the United States. If you choose to use the website or provide information to us, please note that your Personal Information will be processed in Canada.  By providing Personal Information to us for the purpose of using or inquiring about Nucleus’ products, you consent to the transfer of Personal Information to Canada.  The transfer of that Personal Information to Canada is necessary for Nucleus to provide requested information and services.</p>
<p>We do not represent or warrant that the website is appropriate, lawful, or available for use in any particular jurisdiction.  Those who choose to access the website or use our services do so on their own initiative and at their own risk.</p>

<h2>14. <u>Applicable Law</u></h2>
<p>This Privacy Policy, and any disputes arising out of or related hereto, shall be governed exclusively by the laws of the State of North Carolina, without regard to its conflicts of laws rules.  The state and federal courts located in North Carolina shall have exclusive jurisdiction to adjudicate any dispute arising out of or relating to this Privacy Policy. Each party hereby consents to the exclusive jurisdiction of such courts. If there is any conflict or inconsistency between any provision of this Privacy Policy and any provision of any applicable law, the latter shall control.</p>

<h2>15. <u>Severability</u></h2>
<p>Whenever possible, each provision of this Privacy Policy shall be interpreted to be effective and valid under applicable law.  If, however, any such provision shall be prohibited by or invalid under such law, it shall be deemed modified to conform to the minimum requirements of such law, or if for any reason it is not so modified, it shall be prohibited or invalid only to the extent of such prohibition or invalidity without the remainder of such provision, or any other provision of this Privacy Policy, being prohibited or invalid.</p>

<h2>16. <u>Changes to Our Privacy Policy</u></h2>
<p>We reserve the right to amend this Privacy Policy at our discretion and at any time. When we make changes to this Privacy Policy, we will post the updated notice on the website, along with the updated effective date.  Your continued use of the website and/or use of our services following the posting of changes constitutes your acceptance of such changes.</p>

<h2>17. <u>Expenses</u></h2>
<p>Except as provided in this Privacy Policy, or any applicable law, you are solely responsible for all fees and disbursements of any attorney or other advisor retained by you in connection with enforcing your rights under this Privacy Policy.</p>

<h2>18. <u>Our Contact Information</u></h2>

<p>If you have any questions, feedback or to report a violation regarding the Privacy Policy, or the ways in which we collect and use your information described here, your choices and rights regarding such use, or wish to exercise your rights as described herein, please do not hesitate to contact us as follows:</p>

<p>Mail:</p> 	
<p>Nucleus Technologies Inc.<br />
150 Penmarc Drive, Suite 110<br />
Raleigh, NC 27603</p>
<p>Email:</p>
<p><a href='mailto:info@nucleus.com'>info@nucleus.com</a></p>


            </Box>            
            </Stack>
        </Grid>
      </Box>
    );
  }
  