import {
  Box,
  Grid,

  Stack,
  Typography,
} from "@mui/material";

export default function FAQsView() {
  return (
    <Box sx={{ margin: "auto" }}>
      <Grid container spacing={2} margin={0} direction="column">        
      <Stack component="section" direction="column" className="white" padding={2}>
          <Box className="text" >
            <Typography variant="h1">FAQs</Typography>
            <Typography variant="h2">Your FAQs for Alice.</Typography>
            <Typography variant="h3">How can I customize how callers are greeted?</Typography>
            <Typography variant="body1">
            As a Premium user, you can customize both your introductory greeting and goodbye message in the Greetings section of the portal. 
            </Typography>
            <Typography variant="h3">Why am I blocked from transferring calls to my number?</Typography>
            <Typography variant="body1">
            While most destinations in the US & Canada are included, certain high-cost destinations are blocked. This includes any international numbers (non-US or non-Canadian).
            </Typography>
            <Typography variant="h3">Can I transfer to international numbers?</Typography>
            <Typography variant="body1">No, only certain North American numbers destinations are included</Typography>
            <Typography variant="h3">How many custom cards can I add?</Typography>
            <Typography variant="body1">We currently support up to 10 custom knowledge cards</Typography>
            <Typography variant="h3">How many team members can I add?</Typography>
            <Typography variant="body1">We currently support up to 10 team members</Typography>
            <Typography variant="h3">Will you be adding more voices?</Typography>
            <Typography variant="body1">Yes, we’re working with premium voice providers to increase the available voice offering</Typography>
            <Typography variant="h3">What do the “Availability” settings mean?</Typography>
            <Typography variant="body1">
            <strong>Available.</strong> Send calls through → The AI agent will always route calls to you regardless of the day or time of day<br/>
            <strong>Unavailable.</strong> Take a message → The AI agent will always offer to take a message (and deliver via Email or SMS) rather than route calls to you<br/>
            <strong>Schedule.</strong> Accept calls based on timetable below → The AI will only send calls to you during the hours you specify in the schedule widget. Any calls outside of those hours will result in the AI taking a message.
            </Typography>
            <Typography variant="body1">
            Ensure that your preferred time zone (under the “General” settings) is correctly set. Note that unchecking a day of week means that a team member is unavailable for that entire day, and the AI will offer to take a message if a call arrives for that team member on that day. 
            </Typography>
            <Typography variant="h3">What do the “Greetings” settings mean?</Typography>
            <Typography variant="body1">
            <strong>Welcome</strong> → This is the message that is spoken to callers when they first dial into your system. Note the user of the <em>company_name</em> variable. This will be replaced with the company name as you entered it in the “Company name” setting in your portal.<br />
            <strong>Goodbye</strong> → This is the message that is spoken to callers right before the AI intends to hang up the call. This is on a best effort basis, and is determined by the AI’s ability to detect intention of ending the call. 
            </Typography>
            <Typography variant="h3">When building my cards, how do I determine what to put into the title, versus the body?</Typography>
            <Typography variant="body1">
            The title is for your internal use only, helping you organize your cards. It doesn’t affect the model’s instructions or its behavior.
            </Typography>            
            <Typography variant="h3">A caller left me a message, but I didn’t get an email notification.</Typography>
            <Typography variant="body1">
            First, verify your message notifications are set to Email and not SMS<br />
            If that is set, verify there are no typos in your email<br />
            If your email is correct, check your spam/junk folder in case your client blocked it. It may also be beneficial to add donotreply@nucleus.com to your safe list 
            </Typography>     
            <Typography variant="h3">Can I be notified by both Email and SMS?</Typography>
            <Typography variant="body1">
            No, currently you must choose either or.
            </Typography>     
            <Typography variant="h3">I want to designate roles for certain team members such as Sales, Billing, or Bookings. How do I set this up?</Typography>
            <Typography variant="body1">
            You can specify these in the model instructions. Below is an example.
            </Typography>  
            <Typography variant="body1">
            <em>If the caller asks about Sales, transfer them to John Smith.<br />
            If the caller asks about Billing, transfer them to Mary Davidson.<br />
            If the caller asks about Bookings, transfer them to Tony Simpson<br /></em>
            </Typography>    
            <Typography variant="body1">
            If a person handles multiple areas in your company, you may choose to write something like this: 
            </Typography>    
            <Typography variant="body1">
            <em>
            ​​If the caller asks about any of the following items, then transfer them to John: Donations, Organizational mission, finance.<br />
            If the caller asks about any of the following items, transfer them to Mary: Events, Volunteering, Meals. <br />
            If the caller asks about any of the following items, transfer them to Tony: Lodging, Bookings, Reservations, Day Camp<br /></em>
            </Typography>
            <Typography variant="body1">
            These are just suggestions, and ultimately you are free to experiment and see what works best for you.
            </Typography>     
            <Typography variant="h3">I want to provide product info to my callers. How do you recommend I set this up?</Typography>
            <Typography variant="body1">
            You can add a custom card with your product info. Here is an example for a fictional furniture company.
            </Typography>  
            <Typography variant="body1">
            <em>At Everwood Furniture, we take pride in crafting high-quality desks designed to meet the needs of our customers. Our product lines are carefully curated to ensure a balance of aesthetics, functionality, and durability. Below are our key desk product lines and what makes each unique.</em>
            </Typography>  
            <Typography variant="body1">
            <em>Everwood Classic Series: Made from 100% oak wood, traditional design, features spacious surface and cable management, best for home offices and executives, superior for its durability and timeless look.</em>
            </Typography>  
            <Typography variant="body1">
            <em>Everwood Modern Series: Made from walnut wood with steel accents, sleek and minimalist design, features hidden storage, best for modern workplaces, superior for space efficiency and contemporary style.</em>
            </Typography>  
          </Box>            
		  </Stack>
      </Grid>
    </Box>
  );
}
